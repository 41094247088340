import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useMutation, useQuery, gql } from "@apollo/client";
import Navbar from "./Navbar";
import "../styles/Settings.css";
import { Toast } from "primereact/toast";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const MAKE_STUDENT_MODERATOR = gql`
  mutation MakeStudentMorderator($input: MakeStudentModeratorInput!) {
    makeStudentMorderator(input: $input) {
      status
      message
    }
  }
`;

const GET_ALL_STUDENTS = gql`
  query GetAllStudents {
    getAllStudents {
      id
      email
      name
      schoolID
      grade
      age
      moderatorAccess
    }
  }
`;

const ProfileSettings: React.FC = () => {
  const toast = useRef<any>(null);
  const [studentIdVal, setStudentId] = useState<string>('');
  const [students, setStudents] = useState<any[]>([]);

  const showToast = (severity: string, summary: string, detail: string) => {
    if (toast.current) {
      toast.current.show({ severity, summary, detail, life: 3000 });
    }
  };

  const [makeStudentModerator] = useMutation(MAKE_STUDENT_MODERATOR, {
    context: {
      headers: {
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    },
  });

  const { loading, error, data } = useQuery(GET_ALL_STUDENTS, {
    context: {
      headers: {
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    },
  });

  useEffect(() => {
    if (data && data.getAllStudents) {
      setStudents(data.getAllStudents);
    }
  }, [data]);

  const handleSubmit = (studentId: string) => {
    makeStudentModerator({ variables: { input: { studentId: parseInt(studentId) } } })
      .then((response) => {
        if (response.data.makeStudentMorderator.status === true) {
          showToast(
            "success",
            "Success",
            "Student is now a moderator"
          );
          // Update the local state to reflect the change
          setStudents(students.map(student => 
            student.id === studentId ? {...student, moderatorAccess: true} : student
          ));
        }   
      })
      .catch((error) => {
        console.error(error);
        showToast(
          "error",
          "Error",
          error.message
        );
      });
  };

  const actionTemplate = (rowData: any) => {
    return (
      <Button 
        label={rowData.moderatorAccess ? "Moderator" : "Make Moderator"} 
        onClick={() => handleSubmit(rowData.id)}
        disabled={rowData.moderatorAccess}
        className={`p-button-sm ${rowData.moderatorAccess ? 'p-button-secondary' : 'p-button-primary'}`}
        style={{
          backgroundColor: rowData.moderatorAccess ? '#f0f0f0' : '#051da0',
          padding: '5px 10px',
          color: rowData.moderatorAccess ? '#808080' : 'white',
          border: 'none',
          borderRadius: '10px'
        }}
      />
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Navbar buttontext="" />
      <div className="profile-settings">
        {/* <div className="sidebar-settings">
          <ul>
            <li className="selected">Access</li>
          </ul>
        </div> */}
        <div className="content">
          <Card
            title="Settings"
            style={{ borderRadius: "10px", paddingLeft: "30px" }}
          >
            <div className="input-group">
              <label htmlFor="studentId">
                Enter student ID to make moderator
              </label>
              <span className="p-input-icon-left">
                <i className="pi pi-user" style={{ marginLeft: "15px" }}></i>
                <InputText
                  id="studentId"
                  value={studentIdVal !== null ? studentIdVal.toString() : ""}
                  onChange={(e) => setStudentId(e.target.value)}
                  placeholder="Student ID"
                />
              </span>
            </div>
            <div className="input-group">
              <Button
                label="Submit"
                onClick={() => handleSubmit(studentIdVal)}
                style={{}}
                id="mod-submit-btn"
              />
            </div>
          </Card>
          <Card
            title="Students List"
            style={{ borderRadius: "10px", paddingLeft: "30px", marginTop: "20px" }}
          >
            <DataTable value={students} responsiveLayout="scroll">
              <Column field="name" header="Name" />
              <Column field="email" header="Email" />
              <Column body={actionTemplate} header="Action" />
            </DataTable>
          </Card>
        </div>
      </div>
      <Toast ref={toast} position="top-right" />
    </>
  );
};

export default ProfileSettings;